import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-i18n'
import I18n from '../I18n'
import { AppContext } from '../app-context';
import { GeneralData, IArticle, IGeneralData, Articles } from '../StrapiService';
import { useParams } from 'react-router-dom';
import SanitizeHTML from '../components/SanitizeHTML';

interface Params {
  slug?: string
}

function ArticlePage() {
  const appContext = useContext(AppContext)
  const { slug } = useParams<Params>()
  const [article, setArticle] = useState<IArticle | undefined>(undefined)

  useEffect(() => {
    getArticle()
  }, [slug])

  const getArticle = async () => {
    if (slug) {
      setArticle(await Articles.getArticleBySlug(slug, appContext.locale))
    } else setArticle(undefined)
  }
  const [generalData, setGeneralData] = useState<IGeneralData | undefined>(undefined)

  useEffect(() => {
    if (!generalData) {
      getData()
    }
  }, [appContext.locale])
  const getData = async () => {
    const gd = await GeneralData.getGeneralData(appContext.locale)
    setGeneralData(gd?.[0])
  }
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  return (
    <AppContext.Consumer>
      {({locale}) =>
        <div className="article-page strapi-content flex column align-center">
            {article &&
              <div className="max-article px-sm-1">
                <aside className="article-media mt-4 mt-sm-0">
                  <strong className="block">Admin</strong>
                  <span>
                    {`${new Date(article.date).getDay()} ${months[new Date(article.date).getMonth()]}, ${new Date(article.date).getFullYear()}`}
                  </span>
                  <div className="social mt-1">
                    {generalData?.facebook &&
                      <a href={generalData.facebook}>
                        <img src="/picts/svgs/facebook.svg" alt="Facebook" />
                      </a>
                    }
                    {generalData?.instagram &&
                      <a href={generalData.instagram}>
                        <img src="/picts/svgs/instagram.svg" alt="Instagram" />
                      </a>
                    }
                    {generalData?.twitter &&
                      <a href={generalData.twitter}>
                        <img src="/picts/svgs/twitter.svg" alt="Twitter" />
                      </a>
                    }
                  </div>
                </aside>
                <article>
                  <h1>{article.title}</h1>
                  <h2 className="mb-3 mb-sm-2">{article.subtitle}</h2>
                  {article.articleImage && article.articleImage.length > 0 && article.articleImage[0].url &&
                    <>
                      <img className="article-main-image" src={process.env.REACT_APP_STRAPI_URL + article.articleImage[0].url} alt="" />
                      {article.articleImageDescription &&
                        <em className="image-description">{article.articleImageDescription}</em>
                      }
                    </>
                  }
                  {/* <img src="/picts/jpgs/article-example-pict.jpg" className="article-main-image" alt="" /> */}
                  <div className="content mt-2 mb-5 mb-sm-4">
                    {article.excerpt &&
                      <blockquote>
                        <SanitizeHTML html={article.excerpt} />
                      </blockquote>
                    }
                    <SanitizeHTML html={article.content ?? ""} />
                  </div>
                </article>
              </div>
            }
        </div>
      }
    </AppContext.Consumer>
  )
}

export default ArticlePage
