import { ITermsPage } from './types';
import StrapiSDK from './StrapiSDK'

const PAGE_ENDPOINT = `termspage`

export class TermsPageContent {
  static async getTermsPageContent(locale: string) {
    const p = await StrapiSDK.getEntries(`${PAGE_ENDPOINT}?_locale=${locale}`) as ITermsPage[];
    return (Array.isArray(p) ? p[0] : p) as ITermsPage
  }
}
