import React, { useContext, useEffect, useState } from 'react';
import { IAboutPage, AboutPageContent, IGeneralData, GeneralData } from '../StrapiService'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-i18n'
import I18n from '../I18n'
import SanitizeHTML from '../components/SanitizeHTML'
import { AppContext } from '../app-context';

function ProductsPageExample() {
  const appContext = useContext(AppContext)
  const [aboutPage, setAboutPage] = useState<IAboutPage | undefined>(undefined)
  const [generalData, setGeneralData] = useState<IGeneralData | undefined>(undefined)

  useEffect(() => {
    if (!aboutPage) {
      getData()
    }
  }, [appContext.locale])
  const getData = async () => {
    const ap = await AboutPageContent.getAboutPageContent(appContext.locale)
    const gd = await GeneralData.getGeneralData(appContext.locale)
    setGeneralData(gd?.[0])
    setAboutPage(ap?.[0])
  }
  
  return (
    <div className="presentation-page products-page flex column align-center">
      <div className="jumbotron flex column align-center text-center">
        <h1 className="mt-3 mt-sm-4  px-sm-2 px-md-2">
          {aboutPage?.title}
        </h1>
        <h2 className="mt-1 mb-3 px-sm-2 px-md-2">{aboutPage?.subtitle}</h2>
        <div className="presentation">
          <img className="presentation-img" src="/picts/pngs/products-page-main.png" alt="Admin theme" />
        </div>
        <div className="bottom">
          <img src="/picts/svgs/index-jumbo-bottom.svg" alt="" />
        </div>
        <div className="dots-bg">
          <img src="/picts/jpgs/products-page-main-bg.jpg" alt="AI and human" />
        </div>
      </div>
      <div className="max-article">
        <div className="max-content px-sm-2 px-md-2">
          <div className="content flex row mt-1 align-start">
            <article>
              <div className="content mb-4">
                <blockquote>
                  <SanitizeHTML html={aboutPage?.excerpt ?? ""} />
                </blockquote>
                <SanitizeHTML html={aboutPage?.content ?? ""} />
              </div>
              <div className="features columns-2 columns-sm-1 mb-2">
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-ai.svg" alt="" /><span className="ml-1"><I18n t="about.icons.aidiagnoses" /></span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-lens.svg" alt="" /><span className="ml-1"><I18n t="about.icons.lenssupport" /></span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-consultations.svg" alt="" /><span className="ml-1"><I18n t="about.icons.econsultations" /></span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-moleautopos.svg" alt="" /><span className="ml-1"><I18n t="about.icons.moleautopos" /></span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-dicom-hl7.svg" alt="" /><span className="ml-1"><I18n t="about.icons.dicomhl" /></span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-wave-modes.svg" alt="" /><span className="ml-1"><I18n t="about.icons.multimode" /></span>
                </div>
              </div>
              <div className="content mb-5">
                <SanitizeHTML html={aboutPage?.conclusion ?? ""} />
              </div>
            </article>
          </div>
          <div className="download mb-5 text-center">
            <Link to="/upload-photo" className="btn btn-big btn-inline btn-secondary">
              <I18n t="testnow" />
            </Link>
            <div className="platforms mt-05 text-small">
              <I18n t="jumbotron.download.message" /> <a href="//app.smartvissolution.com">
                <I18n t="web" />
              </a>, {generalData?.appstorelink && <a href={generalData?.appstorelink}>iOS</a>}, {generalData?.googleplaylink && <a href={generalData?.googleplaylink}>Android</a>}
            </div>
            <img className="w-100" src="/picts/jpgs/mobile-horizontal-presentation.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsPageExample
