import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-i18n'
import I18n from '../I18n'
import { AppContext } from '../app-context';
import { GeneralData, IGeneralData } from '../StrapiService';

interface Props {
  showMenu?: boolean
  toggleMenu: Function
}

function MobileMenu({showMenu, toggleMenu}: Props) {
  const appContext = useContext(AppContext)
  const [generalData, setGeneralData] = useState<IGeneralData | undefined>(undefined)

  useEffect(() => {
    if (!generalData) {
      getData()
    }
  }, [appContext.locale])
  const getData = async () => {
    const gd = await GeneralData.getGeneralData(appContext.locale)
    setGeneralData(gd?.[0])
  }
  return (
    <AppContext.Consumer>
      {({locale}) =>
        <>
          <div className={`mobile-menu${!showMenu ? ' d-none' : ''}`}>
            <nav className="flex row">
              <ul className="menu flex w-100 align-center" onClick={() => toggleMenu()}>
                {/* <li>
                  <Link to={`${locale === 'en' ? '' : '/' + locale}`}>
                  <img className="logo mt-4" src="/picts/svgs/logo-icon.svg" alt="" />
                  </Link>
                </li> */}
                <li className="flex row justify-content-between w-100 flex-grow">
                  <div className="logo-floating pl-2 pt-2" onClick={() => toggleMenu()}>
                    <Link to={`${locale === 'en' ? '' : '/' + locale}`}>
                      <img src="/picts/svgs/logo-text.svg" alt="" />
                    </Link>
                  </div>
                  <div className="close-menu pr-2 pt-2">
                    <img src="/picts/svgs/close.svg" alt="" onClick={() => toggleMenu()} />
                  </div>
                </li>
                <li>
                  <Link to={`${locale === 'en' ? '' : '/' + locale}`}>
                    <I18n t="header.index" />
                  </Link>
                </li>
                <li>
                  <Link to={`${locale === 'en' ? '' : '/' + locale}/products`}>
                    <I18n t="header.products" />
                  </Link>
                </li>
                <li>
                  <Link to={`${locale === 'en' ? '' : '/' + locale}/blog`}>
                    <I18n t="header.blog" />
                  </Link>
                </li>
                {/* <li>
                  <Link to={`${locale === 'en' ? '' : '/' + locale}/pricing`}>
                    <I18n t="header.pricing" />
                  </Link>
                </li> */}
                <li className="flex-grow">
                  <Link to={`${locale === 'en' ? '' : '/' + locale}/contact-us`}>
                    <I18n t="header.contactus" />
                  </Link>
                </li>
                <li>
                  <Link to={`${locale === 'en' ? '' : '/' + locale}/login`} className="btn btn-outline text-center">
                    <I18n t="header.login" />
                  </Link>
                </li>
                <li className="flex-grow">
                  <Link to={`${locale === 'en' ? '' : '/' + locale}/register`} className="btn btn-primary text-center">
                    <I18n t="header.register" />
                  </Link>
                </li>
                <li className="mb-2">
                  {generalData?.appstorelink &&
                    <a href={generalData?.appstorelink} className="stores">
                      <img src="/picts/svgs/appstore.svg" alt="AppStore" />
                    </a>
                  }
                  {generalData?.googleplaylink &&
                    <a href={generalData?.googleplaylink} className="stores">
                      <img src="/picts/svgs/googleplay.svg" alt="Google Play" />
                    </a>
                  }
                </li>
              </ul>
            </nav>
          </div>
        </>
      }
    </AppContext.Consumer>
  )
}

export default MobileMenu
