import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-i18n'
import I18n from '../I18n'
import { AppContext } from '../app-context';
import { GeneralData, IGeneralData } from '../StrapiService';

interface Props {
  selectReview?: Function
}

function Footer({selectReview}: Props) {
  const appContext = useContext(AppContext)
  const [generalData, setGeneralData] = useState<IGeneralData | undefined>(undefined)

  useEffect(() => {
    if (!generalData) {
      getData()
    }
  }, [appContext.locale])
  const getData = async () => {
    const gd = await GeneralData.getGeneralData(appContext.locale)
    setGeneralData(gd?.[0])
  }
  const isHomePage = (window.location.pathname === '/' || window.location.pathname === '/lt')
  return (
    <AppContext.Consumer>
      {({locale}) =>
        <>
          <footer className={`bg-footer flex column align-center px-sm-2 px-md-2 ${ isHomePage ? 'pt-4' : 'pt-2'}`}>
            <div className="menu max-content flex row align-center">
              <div className="logo flex-grow">
                <Link to={`${locale === 'en' ? '' : '/' + locale}`}>
                  <img src="/picts/svgs/logo-text.svg" alt="Logo" />
                </Link>
              </div>
              <nav className="flex-grow text-right">
                <ul className="menu flex row justify-content-end align-center">
                  <li>
                    <Link to={`${locale === 'en' ? '' : '/' + locale}/products`}>
                      <I18n t="header.products" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${locale === 'en' ? '' : '/' + locale}/blog`}>
                      <I18n t="header.blog" />
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={`${locale === 'en' ? '' : '/' + locale}/pricing`}>
                      <I18n t="header.pricing" />
                    </Link>
                  </li> */}
                  <li>
                    <Link to={`${locale === 'en' ? '' : '/' + locale}/contact-us`}>
                      <I18n t="header.contactus" />
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="media max-content flex row">
              <div className="social flex-grow flex align-center mb-2">
                {generalData?.facebook &&
                  <a href={generalData.facebook} className="mr-1">
                    <img src="/picts/svgs/facebook.svg" alt="Facebook" />
                  </a>
                }
                {generalData?.instagram &&
                  <a href={generalData.instagram} className="mr-1">
                    <img src="/picts/svgs/instagram.svg" alt="Instagram" />
                  </a>
                }
                {generalData?.twitter &&
                  <a href={generalData.twitter} className="mr-1">
                    <img src="/picts/svgs/twitter.svg" alt="Twitter" />
                  </a>
                }
              </div>
              <div className="download flex-grow text-right">
                {generalData?.appstorelink &&
                  <a href={generalData?.appstorelink} className="mr-05">
                    <img src="/picts/svgs/appstore.svg" alt="AppStore" />
                  </a>
                }
                {generalData?.googleplaylink &&
                  <a href={generalData?.googleplaylink} className="">
                    <img src="/picts/svgs/googleplay.svg" alt="Google Play" />
                  </a>
                }
              </div>
            </div>
          </footer>
          <div className="super-footer bg-primary text-light text-small text-center p-05">
            <I18n t="copyright" />
          </div>
        </>
      }
    </AppContext.Consumer>
  )
}

export default Footer
