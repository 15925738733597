import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-i18n'
import I18n from '../I18n'
import { AppContext } from '../app-context';
import { GeneralData, IArticle, IGeneralData, FaqPageContent, IFaqPage } from '../StrapiService';
import { useParams } from 'react-router-dom';
import SanitizeHTML from '../components/SanitizeHTML';

interface Params {
  slug?: string
}

function FaqPage() {
  const appContext = useContext(AppContext)
  const [faqPage, setFaqPage] = useState<IFaqPage | undefined>(undefined)
  
  const getFaqPage = async () => {
    const fp = await FaqPageContent.getFaqPageContent(appContext.locale)
    setFaqPage(fp)
  }
  const [generalData, setGeneralData] = useState<IGeneralData | undefined>(undefined)
  
  useEffect(() => {
    if (!generalData) {
      getData()
    }
  }, [appContext.locale])
  useEffect(() => {
    getFaqPage()
  }, [])
  const getData = async () => {
    const gd = await GeneralData.getGeneralData(appContext.locale)
    setGeneralData(gd?.[0])
  }
  return (
    <div className="faq-page flex column align-center">
        {faqPage &&
          <div className="max-article px-sm-1">
            <article>
              <h1>{faqPage.title}</h1>
              <h2 className="mb-3 mb-sm-2">
                {faqPage.excerpt &&
                  <SanitizeHTML html={faqPage.excerpt} />
                }
              </h2>
              <div className="content mt-2 mb-5 mb-sm-4">
                {faqPage.FaqBlock?.map(fb => (
                  <div className="faq-block">
                    <h3>{fb.question}</h3>
                    <p>
                      {fb.answer &&
                        <SanitizeHTML html={fb.answer} />
                      }
                    </p>
                  </div>
                ))}
              </div>
            </article>
          </div>
        }
    </div>
  )
}

export default FaqPage
