import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import I18n from '../I18n'

const ContactUsMap = () => <MapContainer style={{height: '100%', width: '100%'}} center={[54.917004, 23.987139]} zoom={13} scrollWheelZoom={false}>
  <TileLayer
    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={[54.917004, 23.987139]}>
    <Popup>
      <strong>
        <I18n t="contacts.company" />
      </strong>
      <div>
        <I18n t="contacts.address1" />
      </div>
      <div>
        <I18n t="contacts.address2" />
      </div>
      <div>
        <I18n t="contacts.country" />
      </div>
    </Popup>
  </Marker>
</MapContainer>

export default ContactUsMap
