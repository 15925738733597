import React, { useContext, useState } from 'react'
import { PayPalButtons, usePayPalScriptReducer, DISPATCH_ACTION } from '@paypal/react-paypal-js'
import { AppContext } from '../app-context'
import { IAiImageOrderByOrderId } from '../helpers'

export const CheckoutPayPal = (props: {onPayComplete: Function, price: number, paymentDetails: IAiImageOrderByOrderId, locale?: 'en' | 'lt'}) => {
  const context = useContext(AppContext)
  // NEXT: bring back code when multiple currency model is available
  // const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  // const [currency, setCurrency] = useState(options.currency);
  // const onCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setCurrency(e.target.value);
  //   dispatch({
  //       type: "resetOptions" as DISPATCH_ACTION,
  //       value: {
  //           ...options,
  //           currency: e.target.value,
  //       },
  //   });
  // }
  
  return <>
    {/* <select value={currency} onChange={onCurrencyChange}>
      <option value="USD">United States dollar</option>
      <option value="EUR">Euro</option>
    </select> */}
    {/* {{isPending ? <div className="spinner" /> : null}} */}
    {/* NEXT: make notification component */}
    <PayPalButtons
      style={{ layout: "horizontal" }}
      createOrder={(_, actions) => {
        const description = 'Payment ' + (props.paymentDetails.publicId ? ', order: ' + props.paymentDetails.publicId : '')
        const purchase_units = [
          {
            reference_id: props.paymentDetails.publicId || 'reference-id-not-provided',
            custom_id: props.paymentDetails.publicId || 'custom-id-not-provided',
            description,
            amount: {
              value: props.price.toFixed(2),
              // NEXT: later with multiple currency account choose currency by geo location
              currency_code: 'EUR'
            },
          },
        ]
        return actions.order.create({
          intent: 'CAPTURE',
          purchase_units,
      })}}
      onApprove={async (data, actions) => {
        const order = await actions.order?.capture()
        props.onPayComplete()
      }}
      onError={(error) => {
        context.notify('An error has occurred, please contact administrator:' + error, 'error')
        console.log(error)
      }}
    />
  </>
}