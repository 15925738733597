import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-i18n'
import I18n from '../I18n'
import { AppContext } from '../app-context';
import { Articles, IArticle, ARTICLES_ENDPOINT } from '../StrapiService'
import SanitizeHTML from '../components/SanitizeHTML'
import PaginationIterator from '../components/PaginationIterator'
import { useLocation, useParams } from 'react-router-dom';
import ContactUsMap from '../components/ContactUsMap';
// AIzaSyAPHOlsb_sAeRbg0A4FaWijdBEqCt-DOeg
interface State {
  articles: IArticle[]
  articlesCount: number
}

function ContactUsPage() {
  return (
    <AppContext.Consumer>
      {({locale}) =>
        <div className="contact-us-page flex column align-center h-100">
          <div className="maps d-block w-100 h-100">
            <ContactUsMap />
          </div>
          <div className="flex column align-center w-100 h-100 bg-footer">
            <div className="max-content h-100 flex column px-sm-1 px-md-2 pt-3">
              <h1 className="mb-1"><I18n t="contacts.title" /></h1>
              <p>
                <I18n t="contactspage.message" />
              </p>
              <div className="flex contacts row">
                <div className="contact mt-2 flex-grow">
                  <div className="pb-1">
                    <strong className="block"><I18n t="contacts.company" /></strong>
                  </div>
                  <div>
                    <I18n t="contacts.address1" />
                  </div>
                  <div>
                    <I18n t="contacts.address2" />
                  </div>
                  <div className="pb-1">
                    <I18n t="contacts.country" />
                  </div>
                  <div className="capitalize">
                    <I18n t="phoneshort" />: <I18n t="contacts.phone" />
                  </div>
                  <div>
                    <span className="capitalize"><I18n t="email" /></span>: <a href={`mailto:info@dts-solutions.lt`}>
                      <I18n t="contacts.email" />
                    </a>
                  </div>
                </div>
                <div className="contact mt-2 flex-grow">
                  <div className="pb-1">
                    <strong className="block"><I18n t="contactspage.questionsMessage" /></strong>
                  </div>
                  <div>
                    <I18n t="contactspage.questionsSentence" />
                  </div>
                  <div>
                    <span className="capitalize"><I18n t="email" /></span>: <a href={`mailto:users@smartvissolution.com`}>
                      <I18n t="contactspage.faqEmail" />
                    </a>
                  </div>
                  <div className="mt-2">
                    <Link className="btn btn-primary" to="/faq">
                      <I18n t="contactspage.faqButton" />
                    </Link>
                  </div>
                </div>
                <div className="contact mt-2 flex-grow">
                  <div className="pb-1">
                    <strong className="block"><I18n t="contactspage.pressMessage" /></strong>
                  </div>
                  <div className="capitalize">
                    <I18n t="phoneshort" />: <I18n t="contacts.phone" />
                  </div>
                  <div>
                    <span className="capitalize"><I18n t="email" /></span>: <a href={`mailto:linas.eidimtas@dts-solutions.lt`}>
                      <I18n t="contactspage.pressEmail" />
                    </a>
                  </div>
                  <div className="mt-2">
                    <Link className="btn btn-outline" to="/press-kit"><I18n t="contactspage.pressKit" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </AppContext.Consumer>
  )
}

export default ContactUsPage
