import React, { useContext, useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HomePage from './pages/HomePage'
import PageWrapper from './components/PageWrapper';
import ArticlePage from './pages/ArticlePage';
import ArticlesListPage from './pages/ArticlesListPage';
import ContactUsPage from './pages/ContactUsPage';
import ProductsPage from './pages/ProductsPage';
import ProductsPageExample from './pages/ProductsPageExample';
import UploadPhotoPage from './pages/UploadPhotoPage';
import UploadPayPage from './pages/UploadPayPage';
import UploadResultPage from './pages/UploadResultPage';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Page404 from './pages/Page404';
import { AppContext } from './app-context';
import FaqPage from './pages/FaqPage';
import PolicyPage from './pages/PolicyPage';
import TermsPage from './pages/TermsPage';

function App() {
  const base = '/:locale(en|lt)?';
  const appContext = useContext(AppContext)
  const [locale, setLocale] = useState('en_US')
  useEffect(() => {
    setLocale(appContext.locale === 'lt' ? 'lt_LT' : 'en_US')
  }, [appContext.locale])
  return (
    <PayPalScriptProvider
    key={new Date().toString()}
    options={{
      sdkBaseURL: process.env.REACT_APP_PAYPAL_API_URL!,
      currency: 'EUR',
      intent: 'capture',
      locale,
      "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID!,
    }}>
      <Router>
        <PageWrapper>
          <Switch>
            <Route exact path={base} component={HomePage} />
            <Route path={`${base}/upload-photo`} component={UploadPhotoPage} />
            <Route path={`${base}/upload-pay`} component={UploadPayPage} />
            <Route path={`${base}/ai-result/:publicId`} component={UploadResultPage} />
            <Route path={`${base}/products`} component={ProductsPage} />
            <Route path={`${base}/products-example`} component={ProductsPageExample} />
            <Route path={`${base}/contact-us`} component={ContactUsPage} />
            <Route path={`${base}/blog/:slug`} component={ArticlePage} />
            <Route path={`${base}/blog`} component={ArticlesListPage} />
            <Route path={`${base}/faq`} component={FaqPage} />
            <Route path={`${base}/privacy-policy`} component={PolicyPage} />
            <Route path={`${base}/terms-and-conditions`} component={TermsPage} />
            <Route component={Page404} />
          </Switch>
        </PageWrapper>
      </Router>
    </PayPalScriptProvider>
  );
}

export default App;
