import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-i18n'
import I18n from '../I18n'
import { AppContext } from '../app-context';
import { IGeneralData, GeneralData } from '../StrapiService';
import staticData from '../staticData';
import MobileMenu from './MobileMenu';

function Header() {
  const appContext = useContext(AppContext)
  const [generalData, setGeneralData] = useState<IGeneralData | undefined>(undefined)
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false)
  const getGeneralData = async () => {
    const gd = await GeneralData.getGeneralData(appContext.locale)
    setGeneralData(gd?.[0])
  }
  const date = new Date()

  useEffect(() => {
    if (!generalData) {
      getGeneralData()
    }
  }, [appContext.locale])

  const toggleMenu = (toggle?: boolean) => {
    const t = toggle !== undefined ?? toggleMobileMenu
    setToggleMobileMenu(t)
    document.body.style.overflow = t ? 'hidden' : 'auto'
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      toggleMenu()
   })
  }, [window])

  return (
    <AppContext.Consumer>
    {({locale}) =>
      <>
        <MobileMenu showMenu={toggleMobileMenu} toggleMenu={(t: boolean) => toggleMenu(t)} />
        <header className="flex column justify-center">
          <div className="super-header flex column align-center">
            <div className="max-page flex row px-2 px-sm-1">
              <div className="flex-grow flex align-center">
                {generalData?.newsflashtext && generalData?.newsflashlink &&
                  <>
                    <span className="flex">
                      {generalData?.newsflashtext}
                    </span>
                    <a href={generalData?.newsflashlink} className="btn btn-small ml-1">
                      <I18n t="readmore" />
                    </a>
                  </>
                }
                {(!generalData?.newsflashtext || !generalData?.newsflashlink) &&
                  <span>{date.toLocaleDateString(appContext.locale, staticData.dateOptions as any)}</span>
                }
              </div>
              <div className="languages">
                {locale !== 'lt' &&
                  <a href="/lt" className="flex align-center">
                    Lietuvių
                    <img src="/picts/svgs/flag-lt.svg" alt="Lithuanian language" className="ml-05" />
                  </a>
                }
                {locale === 'lt' &&
                  <a href="/" className="flex align-center">
                    English
                    <img src="/picts/svgs/flag-us.svg" alt="Lithuanian language" className="ml-05" />
                  </a>
                }
                {/* NEXT: styled ul selector for multiple (>2) languages if needed later */}
                {/* <ul className="select-languages d-none">
                  <li>
                    <a href="/en">Anglų<img src="/picts/svgs/flag-us.svg" alt="Lithuanian language" className="ml-05" /></a>
                  </li>
                  <li>
                    <a href="#">Lietuvių<img src="/picts/svgs/flag-lt.svg" alt="Lithuanian language" className="ml-05" /></a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <nav className="flex row justify-center align-center align-self-center max-page text-center">
            <div className="flex-shrink pl-2 pl-sm-1 logo">
              <Link to={`${locale === 'en' ? '' : '/' + locale}`}>
                <img src="/picts/svgs/logo-text.svg" alt="Logo" />
              </Link>
            </div>
            <div className="flex-grow text-right pr-2 pr-sm-1">
              <ul className="menu flex row justify-content-end align-center">
                <li>
                  <Link to={`${locale === 'en' ? '' : '/' + locale}/products`}>
                    <I18n t="header.products" />
                  </Link>
                </li>
                <li>
                  <Link to={`${locale === 'en' ? '' : '/' + locale}/blog`}>
                    <I18n t="header.blog" />
                  </Link>
                </li>
                {/* <li>
                  <Link to={`${locale === 'en' ? '' : '/' + locale}/pricing`}>
                    <I18n t="header.pricing" />
                  </Link>
                </li> */}
                <li>
                  <Link to={`${locale === 'en' ? '' : '/' + locale}/contact-us`}>
                    <I18n t="header.contactus" />
                  </Link>
                </li>
                <li className="pl-3">
                  <a href='https://app.smartvissolution.com' className="btn btn-primary">
                    <I18n t="header.app" />
                  </a>
                </li>
                {/* NEXT: bring back when login is available */}
                {/* <li className="pl-3">
                  <a href='https://app.smartvissolution.com' className="btn btn-outline">
                    <I18n t="header.login" />
                  </a>
                </li>
                <li className="pl-1">
                  <a href='https://app.smartvissolution.com' className="btn btn-primary">
                    <I18n t="header.register" />
                  </a>
                </li> */}
                <li className="mob-menu-trigger">
                  <Link onClick={() => toggleMenu(true)} className="btn btn-primary">
                    <I18n t="header.menu" />
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </>
    }
  </AppContext.Consumer>
)
}

export default Header
