import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../app-context';
import { TermsPageContent, ITermsPage } from '../StrapiService';
import SanitizeHTML from '../components/SanitizeHTML';

function TermsPage() {
  const appContext = useContext(AppContext)
  const [termsPage, setTermsPage] = useState<ITermsPage | undefined>(undefined)
  const getPage = async () => {
    const tp = await TermsPageContent.getTermsPageContent(appContext.locale)
    setTermsPage(tp)
  }

  useEffect(() => {
    getPage()
  }, [])

  return (
    <div className="faq-page flex column align-center">
        {termsPage &&
          <div className="max-article px-sm-1">
            <article>
              <h1>{termsPage.title}</h1>
              <div className="content mt-2 mb-5 mb-sm-4">
                {termsPage.terms &&
                  <SanitizeHTML html={termsPage.terms} />
                }
              </div>
            </article>
          </div>
        }
    </div>
  )
}

export default TermsPage
