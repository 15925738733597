import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-i18n'
import I18n from '../I18n'
import { AppContext } from '../app-context';
import { GeneralData, IArticle, IGeneralData, Articles } from '../StrapiService';
import { useParams } from 'react-router-dom';
import SanitizeHTML from '../components/SanitizeHTML';

interface Params {
  slug?: string
}

function Page404() {
  const appContext = useContext(AppContext)

  const [generalData, setGeneralData] = useState<IGeneralData | undefined>(undefined)

  useEffect(() => {
    if (!generalData) {
      getData()
    }
  }, [appContext.locale])
  const getData = async () => {
    const gd = await GeneralData.getGeneralData(appContext.locale)
    setGeneralData(gd?.[0])
  }
  return (
    <AppContext.Consumer>
      {({locale}) =>
        <div className="article-page flex column align-center">
          <div className="mt-2 text-center">
            <I18n t="page404" /><br /><br />
            <Link to="/">
              <I18n t="gohome" />
            </Link>
          </div>
        </div>
      }
    </AppContext.Consumer>
  )
}

export default Page404
