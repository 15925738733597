import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-i18n'
import I18n from '../I18n'
import { AppContext } from '../app-context';
import { Articles, IArticle, ARTICLES_ENDPOINT } from '../StrapiService'
import SanitizeHTML from '../components/SanitizeHTML'
import PaginationIterator from '../components/PaginationIterator'
import { useLocation, useParams } from 'react-router-dom';

interface State {
  articles: IArticle[]
  articlesCount: number
}

function ArticlesListPage() {
  const appContext = useContext(AppContext)
  const location = useLocation()
  const [page, setPage] = useState(1)
  const [articles, setArticles] = useState<IArticle[] | undefined>(undefined)
  const [articlesCount, setArticlesCount] = useState(0)

  const getStartPage = (p: string | null) => {
    return p ? (parseInt(p) - 1) * Articles.articlesPerPage : 0
  }

  useEffect(() => {
    getData()
  }, [appContext.locale, location])

  const getData = async () => {
    const params = new URLSearchParams(location.search)
    const p = params.get('page');
    setPage(p ? parseInt(p) : 1)
    const a = await Articles.getArticlesWithLimit(appContext.locale, getStartPage(p), Articles.articlesPerPage)
    setArticles(Array.isArray(a) ? a : [a])
    setArticlesCount(await Articles.getArticlesCount(appContext.locale))
  }
  
  const articlesList = articles?.map((a, i) => {
    return (
      <Link key={`article-${i}`} className={`list-item ${(page === 1 && i === 0) ? 'first-article': 'inline'}`} to={`/blog/${a.slug}`}>
        {a.articleImage && a.articleImage.length > 0 && a.articleImage[0].url &&
          <img className="article-image" src={process.env.REACT_APP_STRAPI_URL + a.articleImage[0].url} alt="" />
        }
        {a.articleImage && (a.articleImage.length === 0 || !a.articleImage[0].url) &&
          <div className="no-image">
            <img src="/picts/svgs/no-image.svg" alt="" />
          </div>
        }
        <strong>{a.title}</strong>
      </Link>
    )
  })
  // articles?.[0]
  const lastArticle = (a?: IArticle) => {
    return (
      a &&
      <div className="last-article">
        <Link to={`/blog/${a.slug}`}>
          <section className="last-article-image">
            {a.articleImage && a.articleImage.length > 0 && a.articleImage[0].url &&
              <img src={process.env.REACT_APP_STRAPI_URL + a.articleImage[0].url} alt="" />
            }
            <div>
              <title>{a.title}</title>
              <strong>{a.subtitle}</strong>
            </div>
          </section>
        </Link>
        <aside>
          <SanitizeHTML html={(a.excerpt ?? "").slice(0, 500) + ((a.excerpt ?? '').length > 500 ? '...' : '')} />
          <Link to={`/blog/${a.slug}`} className="btn btn-small"><I18n t="readmore" /></Link>
        </aside>
      </div>
      )
    }

  return (
    <AppContext.Consumer>
      {({locale}) =>
        <div className="articles-list-page flex column align-center h-100 px-sm-1 px-md-2">
          <div className="max-content h-100 flex column">
            <h1 className="mb-2"><I18n t="articles.title" /></h1>
            {lastArticle(page === 1 ? articles?.[0] : undefined)}
            <div className="articles-list flex-grow">
              {articlesList}
              {articlesList?.length === 0 &&
                <span><I18n t="articles.noArticles" /></span>
              }
            </div>
            <PaginationIterator
              itemsCount={articlesCount}
              currentPage={page}
              pageItemsLimit={Articles.articlesPerPage}
              path={'blog'}
              showMaxPages={5}
            />
          </div>
        </div>
      }
    </AppContext.Consumer>
  )
}

export default ArticlesListPage
