import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';
import { AppContext, Locales, NotificationType } from '../app-context';
import toast, { Toaster } from 'react-hot-toast';
interface Props {
  children:  JSX.Element | JSX.Element[] | undefined
}

function PageWrapper({children}: Props) {
  const [locale, setLocale] = useState<Locales>("en")
  const location = useLocation();
  useEffect(() => {
    let l = location.pathname.slice(1, 3)
    l = l === 'lt' ? l : 'en'
    setLocale(l as Locales)
    window.scrollTo(0, 0)
  }, [location])
  return (
    <div className="App">
      <AppContext.Provider value={{locale, notify: (message: string, toastType: NotificationType) => {
        if (toastType === 'normal') toast(message, {duration: 5000})        
        if (toastType === 'error') toast.error((t) => <div className="flex">
              <div>
                {message}
              </div>
              <button className="icon-button" onClick={() => toast.dismiss(t.id)}>
                <img src="/picts/svgs/close_white.svg" />
              </button>
            </div>
        , {duration: 360000000, icon: '', style: {
          color: 'white',
          backgroundColor: '#EB5757',
          ...(message.length > 300 ? {minWidth: '100%'} : undefined)
        }})  
        if (toastType === 'success') toast.success(message, {duration: 5000})        
      }}}>
        <Header />
        <div className="app-content">
          {children}
        </div>
        <Footer />
        <Toaster />   
      </AppContext.Provider>
    </div>
  )
}

export default PageWrapper
