import React from 'react'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { useRef } from 'react'

type Props = {
  photoCallback?: Function
  children: JSX.Element | JSX.Element[]
}

export function PhotoDropzone ({children, photoCallback}: Props) {
  const dropzoneRef = useRef<DropzoneRef>(null)
  return (
    <Dropzone accept='image/jpeg, image/png' ref={dropzoneRef} multiple={false} onDrop={async (files) => {
      if (files.length === 1 && photoCallback) {
          photoCallback(files[0])
        }
      }}>
      {({getRootProps, getInputProps}) => {
        return (
          // @ts-ignore
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {children}
          </div>
        )
      }}
    </Dropzone>
  )
}
