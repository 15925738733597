export const WEB_HOST = process.env.REACT_APP_WEB_URL as string
export const API_HOST = process.env.REACT_APP_API_URL as string
export const CMS_HOST = process.env.REACT_APP_STRAPI_URL as string

const API_AI_POST = `${API_HOST}/aiImageOrder`
const API_AI_ORDER = (orderId: string) => `${API_HOST}/aiImageOrder/${orderId}`
const API_AI_IMAGES = (orderId: string, imageId: string) => `${API_HOST}/aiImageOrder/${orderId}/${imageId}`
const API_AI_PAYMENT = (orderId: string) => `${API_HOST}/aiImageOrder/${orderId}/payment`

export enum DiagnosisCode {
  c43 = 'c43',
  d22 = 'd22',
  c4491 = 'c4491',
  c4492 = 'c4492',
  l57 = 'l57',
  l82 = 'l82',
  d23 = 'd23',
  h02 = 'h02',
}

/**
 * AI algorith returns codes that are not a standard, we remap to the standard values
 */
export const DiagnosisAiClassNameDict = {
  MEL: 'c43',
  NV: 'd22',
  BCC: 'c4491',
  SCC: 'c4492',
  AK: 'l57',
  BKL: 'l82',
  DF: 'd23',
  VASC: 'h02',
}

export enum DiagnosisTitle {
  'c43' = 'Melanoma',
  'd22' = 'Melanocytic nevus - mole',
  'c4491' = 'Carcinoma, Basal cell',
  'c4492' = 'Carcinoma, Squamous cell',
  'l57' = 'Actinic keratosis',
  'l82' = 'Seborrheic - benign keratosis',
  'd23' = 'Dermatofibroma',
  'h02' = 'Vascular lesion',
}

export interface IDiagnosisAiResult {
  className: keyof typeof DiagnosisAiClassNameDict
  icdCode: keyof typeof DiagnosisCode
  probability: number
  rect: {
    leftX: number
    topY: number
    rightX: number
    bottomY: number
  }
}

export interface IAiImageOrderByOrderId {
  imageUrl: string,
  id: string,
  publicId?: string,
  results: IDiagnosisAiResult[]
  type: 'AiImage',
  createdAt: string,
  updatedAt: string,
  status: "PendingPayment" | "ProcessingPayment" | "Processing" | "Completed" | "Refunded" | "Failed" | "Cancelled",
  payments: {
    Stripe: {
      clientSecret: string,
      type: "Stripe"
    },
    Paypal: {
      type: "Paypal"
    }
  }
}

const setIcdCodesForAiResults = (aiResults: IDiagnosisAiResult[]) => {
  return aiResults.map(ar => {
    ar.icdCode = DiagnosisAiClassNameDict[ar.className] as keyof typeof DiagnosisCode
    return ar
  })
}

const dataUrlToBlob = function (url: string) {
  const arr = url.split(',')
  const mime = arr[0].match(/:(.*?);/)?.[1] || 'image-jpeg'
  const str = atob(arr[1])
  let length = str.length
  const uintArr = new Uint8Array(length)
  while (length--) {
    uintArr[length] = str.charCodeAt(length)
  }
  return new Blob([uintArr], { type: mime })
}

const resizeImage = (imageBlob: Blob): Promise<Blob> => {
  return new Promise(function (resolve, reject) {
      const reader = new FileReader()
      reader.readAsDataURL(imageBlob)
      reader.addEventListener('load', function (e) {
          const ele = new Image()
          ele.addEventListener('load', function () {
              const canvas = document.createElement('canvas')
              const context = canvas.getContext('2d')
              const maxSize = 1000
              const ratio = ele.width / ele.height
              let w = ele.width
              let h = ele.height
              if (w > h && w > maxSize) {
                w = maxSize
                h = w / ratio
              } else {
                if (h > w && h > maxSize) {
                  h = maxSize
                  w = h * ratio
                }
              }
              canvas.width = w
              canvas.height = h
              context!.drawImage(ele, 0, 0, w, h)
              const url = canvas.toDataURL('image/jpeg')
              // TO TEST IT VISUALLY bring this image tag in public/index.html file
              // <img id="immmm" style="display: flex; min-height: 300px;min-width: 300px;position: absolute; background: red; z-index: 10000;top: 0;left:0" />
              // // @ts-ignore
              // document.getElementById('immmm').src = url
              resolve(dataUrlToBlob(url) as Blob)
          })
          ele.src = e.target?.result as string
      });

      reader.addEventListener('error', function (e) {
          reject()
      })
  })
}

const fileToBlob = async (file: File) => new Blob([new Uint8Array(await file.arrayBuffer())], {type: file.type });

export const getAiResultForImage = async (file: File): Promise<IDiagnosisAiResult[]> => {
  let result: IDiagnosisAiResult[] = []
  try {
    let blob = await fileToBlob(file)
    const maxFileSize = 4 * 1024 * 1024 // 4Mbs
    if (blob.size > maxFileSize) {
      blob = await resizeImage(blob)
    }
    const body = new FormData()
    body.append('image', blob, 'image.jpg')
    const res = await fetch(API_AI_POST, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body
    })
    const air = (await res.json()).moles || []
    result = setIcdCodesForAiResults(air as IDiagnosisAiResult[])
  }
  catch(err) {
    console.log(err)
  }
  return result
}

export const findDiagnosisByMaxProbability = (aiResults?: IDiagnosisAiResult[]) => {
  aiResults = aiResults || []
  if (aiResults.length > 0) {
    let p = 0
    let d = aiResults[0]
    for (const aiResult of aiResults) {
      if (aiResult.probability > p) {
        p = aiResults[0].probability
        d = aiResults[0]
      }
    }
    return d
  }
  return undefined
}

const addIcdCodesToOrder = (order: IAiImageOrderByOrderId) => {
  // NEXT: remove this after publicId is returned from the api server
  if (order.id && !order.publicId) {
    order.publicId = order.id
  }

  // TEST: should be removed after server pending payment bug refactored, since then it can be used as the result from the server
  /*
  order.status = 'Completed'
  order.results = [
    {
      className: 'AK',
      icdCode: DiagnosisAiClassNameDict['BCC'] as DiagnosisCode,
      probability: 0.5,
      rect: {
        bottomY:0,
        leftX: 0,
        rightX: 0,
        topY: 0,
      }
    },
    {
      className: 'AK',
      icdCode: DiagnosisAiClassNameDict['AK'] as DiagnosisCode,
      probability: 0.4,
      rect: {
        bottomY:0,
        leftX: 0,
        rightX: 0,
        topY: 0,
      }
    }
  ]
  */
  const results = order.results?.map(r => {
    r.icdCode = DiagnosisAiClassNameDict[r.className] as DiagnosisCode
    return r
  })
  order = {...order, results}
  return order
}

export const preparePayment = async (image: File, price: number): Promise<IAiImageOrderByOrderId | undefined> => {
  try {
    let blob = await fileToBlob(image)
    const maxFileSize = 4 * 1024 * 1024 // 4Mbs
    if (blob.size > maxFileSize) {
      blob = await resizeImage(blob)
    }
    const body = new FormData()
    body.append('image', blob, 'image.jpg')
    body.append('price', price.toString())

    const res = await fetch(API_AI_POST, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body
    })
    let order = await res.json() as IAiImageOrderByOrderId
    order = addIcdCodesToOrder(order)
    return order
  }
  catch(err) {
    console.log(err)
  }
  return undefined
}

export const setPaymentData = async (publicId: string, type: string, price: number) => {
  try {
    const body = new FormData()
    body.append('type', type)
    body.append('price', price.toString())
    const res = await fetch(API_AI_PAYMENT(publicId), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body
    })
  }
  catch(err) {
    console.log(err)
  }
  return undefined
}

export const getOrderDetails = async (publicId: string): Promise<IAiImageOrderByOrderId | undefined> => {
  try {
    const res = await fetch(API_AI_ORDER(publicId), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    let order = await res.json() as IAiImageOrderByOrderId
    order = addIcdCodesToOrder(order)
    return order
  }
  catch(err) {
    console.log(err)
  }
  return undefined
}