import { createI18n } from 'react-router-i18n';

// Array of supported locales
// The first in the array is treated as the default locale
const locales = ['en', 'lt'];

// Dictionary of translations
export const translations = {
  en: {
    header: {
      app: 'Web App',
      login: 'Log In',
      register: 'Register',
      visit: 'Visit',
      index: 'Index Page',
      products: 'Products',
      blog: 'Blog',
      pricing: 'Pricing',
      contactus: 'Contact Us',
      about: 'About',
      menu: 'Menu',
    },
    jumbotron: {
      download: {
        message: 'For more functionalities use our app on'
      }
    },
    about: {
      title: 'About',
      subtitle: 'the application',
      icons: {
        aidiagnoses: 'AI Diagnoses',
        moleautopos: 'MoleAutoPos',
        lenssupport: 'Lens Support',
        econsultations: 'E-consultations',
        dicomhl: 'DICOM and HL7',
        multimode: 'Multi lightwave modes'
      },
    },
    usecases: 'Use Cases',
    articles: {
      title: 'Articles',
      noArticles: 'No articles found',
    },
    upload: {
      photo: {
        getimmediately: 'Get results immediately',
        message: 'Your picture of mole should be analyzed with our advanced artificial intelligence algorithms. Grab the picture to the frame or press upload button.',
        howtotitle: 'How to take a picture',
        cropmessage: 'Slide picture to center the mole, zoom photo by scrolling on the picture, crop photo by pressing crop button.',
        crop: 'Crop the picture'
      },
      pay: {
        title: 'Pay and get the result',
        subtitle: 'Consider subscription if you need more',
        badpicture: 'Not happy with the picture',
        another: 'Choose another one »',
        onephoto: '1 photo analysis',
        onephotomessage: 'This is one photo analysis and you will get AI diagnosis for this particular image.',
        savemoneymessage: 'If you want to save money you can purchase monthly subscription which you can cancel anytime, just €4.99 per month and you will get unlimited pictures upload.',
        free: 'Get for free',
        freefull: 'Get results for free',
        billingdetails: 'Billind details',
        paymentsuccess: 'Payment successful',
        thanksmessage: 'Thank you for trying our services. Here is your order ID:',
        paywithcard: 'Pay with credit card',
        paywithcard_unavailable: 'Loading... If this operation is delayed try to reload page or try later.',
        cardno: 'Card number',
        pay: 'Pay',
        compare: 'Compare options'
      },
      result: {
        title: 'Test result',
        diagnosis: 'Diagnosis',
        senddiagnosis: 'Send diagnosis to your email',
        emailaddress: 'email address',
        uploadanother: 'Upload another picture',
        emailsent: 'The email was sent.',
        emailcontent: {
          testresults: 'Test results',
          riskassessment: 'Risk assessment',
          conclusion: 'Conclusion',
          treatment: 'Treatment',
          advice: 'Advice',
          diagnosis: 'Diagnosis',
          filename: 'results.pdf',
        },
        diagnoses: {
          c4491: {
            title: 'Carcinoma, Basal cell',
            message: 'Signs of skin cancer were detected.',
            advice: 'You need to contact a dermatologist as soon as possible for additional diagnostics and treatment.',
            // NEXT: would be nice in the future to add content to additional fields
            treatment: undefined,
            extra: undefined,
            diagnosis: undefined,
          },
          c4492: {
            title: 'Carcinoma, Squamous cell',
            message: 'Signs of skin cancer were detected.',
            advice: 'You need to contact a dermatologist as soon as possible for additional diagnostics and treatment.',
          },
          c43: {
            title: 'Melanoma',
            message: 'Signs of melanoma were detected.',
            advice: 'You need to contact a dermatologist as soon as possible for additional diagnostics and treatment.',
          },
          l82: {
            title: 'Seborrheic keratosis',
            message: 'Benign lesion.',
            advice: 'Consult a dermatologist if the lesion experiences rapid growth, bleeding, or changes in colour or shape.'
          },
          l57: {
            title: 'Actinic keratosis',
            message: 'Benign lesion.',
            advice: 'Consult a dermatologist if the lesion experiences rapid growth, bleeding, or changes in colour or shape.'
          },
          d22: {
            title: 'Melanocytic nevus',
            message: 'Benign lesion.',
            advice: 'Consult a dermatologist if the lesion experiences rapid growth, bleeding, or changes in colour or shape'
          },
          d23: {
            title: 'Dermatofibroma',
            message: 'Benign lesion.',
            advice: 'Consult a dermatologist if the lesion experiences rapid growth, bleeding, or changes in colour or shape'
          },
          h02: {
            title: 'Vascular lesion',
            message: 'Vascular lesion.',
            advice: 'Consult a dermatologist if the lesion experiences rapid growth, bleeding, or changes in colour or shape'
          },
          none: {
            title: 'Nothing serious found',
            message: 'Unfortunately, the algorithm could not detect a lesion.',
            advice: 'Please try to retake the photo, make sure that the lesion is centered',
          },
        },
        ordernotfulfilled: 'If you feel, that your order is not fulfilled, please copy this order number and write us an email and we will answer it as soon as we can',
        clickcopyorderid: 'Click to copy your order ID',
        ordernotfulfilled_nodata: 'If you feel, that your order is not fulfilled, please contact us via email and describe what happened. We will answer as soon as we can',
        orderid: 'Order ID',
        otherdiagnoses: 'Other diagnoses',
        waitingpaymentresults: 'We are waiting for payment results. This page reloads automatically and will show updates as the payment will be seen on our servers. Write down this url link, so you can visit it any time to check again',
        pagewillreload: 'Page will automatically reoload in',
        reloadpage: 'Reload page now',
        paymentrefunded: 'Your payment is refunded, you can create another order by visiting homepage',
        paymentcancelled: 'Your payment is cancelled, try to create another order by visiting homepage and start again',
        paymentfailed: 'Your order has failed, try to create another order by visiting homepage and start again',
        orderiscopied: 'Order number is copied to the clipboard',
        madeonweb: 'The order was made on the website',
      }
    },
    gohome: 'Open index page',
    readmore: 'Read more',
    print: 'Print',
    send: 'Send',
    uploadphoto: 'Upload photo',
    download: 'Download',
    datasheet: 'Datasheet',
    web: 'web',
    copyright: 'SmartVisSolution team, all rights reserved, 2021 ©',
    login: 'Log In',
    getstarted: 'Get Started',
    testnow: 'Get Tested Now',
    phoneshort: 'phone',
    email: 'email',
    name: 'name',
    phone: 'phone',
    page404: 'Page does not exist or content is not ready yet.',
    contacts: {
      title: 'Contact Us',
      company: 'JSC "Dts Solutions"',
      code: '135885245',
      address1: 'Taikos av. 88A',
      address2: 'Kaunas, LT-51183',
      country: 'Lithuania',
      phone: '+370 7 266145',
      email: 'info@dts-solutions.lt',
    },
    contactspage: {
      message: 'Our team is ready to help. We are waiting for your messages and visits. For any inquiries or questions please contact us via email or phone provided below and we will answer as soon as possible.',
      questionsMessage: 'For questions and app issues',
      questionsSentence: 'If you have any issues with an app, please read our FAQ',
      faqButton: 'Open FAQ',
      faqEmail: 'users@smartvissolution.com',
      pressEmail: 'linas.eidimtas@dts-solutions.lt',
      pressMessage: 'For media queries',
      pressKit: 'Press Kit',
    }
  },
  lt: {
    header: {
      app: 'Aplikacija',
      login: 'Prisijungti',
      register: 'Registruotis',
      visit: 'Atidaryti',
      index: 'Titulinis puslapis',
      products: 'Produktai',
      blog: 'Naujienos',
      pricing: 'Kainos',
      contactus: 'Kontaktai',
      about: 'Apie mus',
      menu: 'Meniu',
    },
    jumbotron: {
      download: {
        message: 'Aplikacija yra taip pat pasiekiama'
      }
    },
    about: {
      title: 'Apie',
      subtitle: 'mūsų aplikaciją',
      icons: {
        aidiagnoses: 'AI diagnozės',
        moleautopos: 'MoleAutoPos',
        lenssupport: 'Lęšių palaikymas',
        econsultations: 'E-konsultacijos',
        dicomhl: 'DICOM ir HL7',
        multimode: 'Šviesos bangų režimai'
      },
    },
    usecases: 'Panaudojimo būdai',
    articles: {
      title: 'Straipsniai',
      noArticles: 'Straipsnių nėra',
    },
    upload: {
      photo: {
        getimmediately: 'Gaukite rezultatus akimirksniu',
        message: 'Jūsų apgamo nuotrauka turi būti išanalizuota su pažangiais dirbtinio intelekto algoritmais. Atvilkite paveikslėlį ant rėmelio arba spauskite "Įkelti" mygtuką.',
        howtotitle: 'Kaip paruošti ir įkelti nuotrauką',
        cropmessage: 'Slinkite nuotrauką, kad išcentruotumėte apgamą, sukite pelės ratuką and paveikslėlio, kad jį priartinti, iškirpkite nuotrauką pagal rėmelį spausdami mygtuką kirpti.',
        crop: 'Kirpti nuotrauką'
      },
      pay: {
        title: 'Sumokėkite ir gaukite analizę',
        subtitle: 'Apsvarstykite prenumeratą jei reikia daugiau',
        badpicture: 'Netiko paveikslėlis',
        another: 'pasirinkite kitą »',
        onephoto: 'Vienos nuotraukos analizė',
        onephotomessage: 'Tai vienos nuotraukos analizė ir Jūs gausite įkeltos nuotraukos dirbtinio intelekto diagnozę.',
        savemoneymessage: 'Jei norite sutaupyti pasirinkite prenumeratą, kurią galėsite atšaukti bet kada tik €4.99 per mėnesį neribotam skaičiui testų.',
        free: 'Gaukite nemokamai',
        freefull: 'Gaukite rezultatus nemokamai',
        paywithcard: 'Sumokėkite banko kortele',
        paywithcard_unavailable: 'Kraunama... Jei ši operacija užtruko prašome perkrauti puslapį ar pabandyti vėliau.',
        cardno: 'Kortelės numeris',
        billingdetails: 'Mokėjimo detalės',
        paymentsuccess: 'Mokėjimas sėkimngai atliktas',
        thanksmessage: 'Dėkojame, kad naudojatės mūsų paslaugomis. Sugeneruotas paslaugos ID:',
        pay: 'Mokėti',
        compare: 'Palyginti variantus',
      },
      result: {
        title: 'Testo rezultatai',
        diagnosis: 'Diagnozė',
        senddiagnosis: 'Parsisiųsti diagnozę e-paštu',
        emailaddress: 'pašto adresas',
        emailsent: 'Laiškas išsiųstas.',
        uploadanother: 'Įkelti kitą nuotrauką',
        emailcontent: {
          testresults: 'Testo rezultatai',
          riskassessment: 'Rizikos vertinimas',
          conclusion: 'Išvada',
          treatment: 'Gydymas',
          advice: 'Patarimas',
          diagnosis: 'Diagnozė',
          filename: 'rezultatai.pdf',
        },
        // diagnoses: {
        //   c44: {
        //     title: 'Karcinoma',
        //     message: 'LTSigns of malignant neoplasm of the skin were detected. You need to contact an oncologist as soon as possible for additional diagnostics.',
        //     treatment: 'Operacinis pašalinimas.',
        //     advice: 'Kuo skubiau kreipkitės į specialistą dermotologą',
        //     extra: 'Tiksli diagnozė: po biopsijos',
        //     diagnosis: 'Po biopsijos',
        //   },
        //   c43: {
        //     title: 'Melanoma',
        //     message: 'LTSigns of malignant neoplasm of the skin were detected. You need to contact an oncologist as soon as possible for additional diagnostics.',
        //     treatment: 'Operacinis pašalinimas.',
        //     advice: 'Kuo skubiau kreipkitės į specialistą dermotologą',
        //     diagnosis: 'Po biopsijos',
        //   },
        //   l82: 'Sebrėjinė keratozė',
        //   b22: 'Apgamas',
        //   none: 'Nieko rimto neaptikta',
        //   all: 'Visos diagnozės'
        // },
        diagnoses: {
          c4491: {
            title: 'Odos karcinoma',
            message: 'Nustatyti odos vėžio požymiai.',
            advice: 'Prašome nedelsiant kreiptis į gydytoją dermatovenerologą papildomam ištyrimui ir gydymui',
            // NEXT: would be nice in the future to add content to additional fields
            treatment: undefined,
            extra: undefined,
            diagnosis: undefined,
          },
          c4492: {
            title: 'Odos karcinoma',
            message: 'Nustatyti odos vėžio požymiai.',
            advice: 'Prašome nedelsiant kreiptis į gydytoją dermatovenerologą papildomam ištyrimui ir gydymui',
          },
          c43: {
            title: 'Melanoma',
            message: 'Nustatyti melanomos požymiai.',
            advice: 'Prašome nedelsiant kreiptis į gydytoją dermatovenerologą papildomam ištyrimui ir gydymui',
          },
          l82: {
            title: 'Seborėjinė keratozė',
            message: 'Gerybinis derinys.',
            advice: 'Kreipkitės į gydytoją dermatovenerologą jei darinys greitai auga, kraujuoja arba keičia spalvą, formą.'
          },
          l57: {
            title: 'Aktininė keratozė',
            message: 'Gerybinis derinys.',
            advice: 'Kreipkitės į gydytoją dermatovenerologą jei darinys greitai auga, kraujuoja arba keičia spalvą, formą.'
          },
          d22: {
            title: 'Apgamas',
            message: 'Gerybinis derinys. Melanocitinis apgamas.',
            advice: 'Kreipkitės į gydytoją dermatovenerologą jei darinys greitai auga, kraujuoja arba keičia spalvą, formą.'          },
          d23: {
            title: 'Dermatofibroma',
            message: 'Gerybinis derinys.',
            advice: 'Kreipkitės į gydytoją dermatovenerologą jei darinys greitai auga, kraujuoja arba keičia spalvą, formą.'
          },
          h02: {
            title: 'Vascular lesion',
            message: 'Gerybinis derinys.',
            advice: 'Kreipkitės į gydytoją dermatovenerologą jei darinys greitai auga, kraujuoja arba keičia spalvą, formą.'
          },
          none: {
            title: 'Nieko rimto neaptikta',
            message: 'Atsiprašome, tačiau algoritmas negalėjo aptikti darinio.',
            advice: 'Prašome pabandyti pakartotinai nufotografuoti, užtikrinant, kad darinys yra centre',
          },
        },
        ordernotfulfilled: 'Jei manote, kad Jūsų užsakymas nebuvo pilnai įvykdytas, prašome nukopijuoti Jūsų užsakymo numerį ir išsiųsti mums elektroninį laišką ir mes Jums atrašysime netrukus',
        clickcopyorderid: 'Paspauskite, kad nukopijuotumėte užsakymo Nr.',
        ordernotfulfilled_nodata: 'Jei manote, kad Jūsų užsakymas nebuvo pilnai įvykdytas, prašome išsiųsti mums elektroninį laišką su užsakymo detalėmis ir mes Jums atrašysime netrukus',
        orderid: 'Užsakymo Nr.',
        otherdiagnoses: 'Kitos diagnozės',
        waitingpaymentresults: 'Mes laukiame mokėjimo rezultatų. Šis puslapis persikraus automatiškai ir parodys atnaujinimus tada kai mokėjimas bus matyti mūsų serveriuose. Išsisaugokite šią nuorodą, kad galėtumėte patikrinti informaciją bet kuriuo metu',
        pagewillreload: 'Puslapis automatiškai persikraus už',
        reloadpage: 'Perkrauti puslaptį dabar',
        paymentrefunded: 'Jūsų mokėjimo suma buvo grąžinta, galite atlikti naują užsakymą tituliniame puslapyje',
        paymentcancelled: 'Jūsų mokėjimas buvo atšauktas, galite atlikti naują užsakymą tituliniame puslapyje',
        paymentfailed: 'Mokėjimo atlikti nepavyko, galite atlikti naują užsakymą tituliniame puslapyje',
        orderiscopied: 'Užsakymo numeris nukopijuotas į atmintį',
        madeonweb: 'Užsakymas atliktas puslapyje',
      }
    },
    gohome: 'Atidaryti titulinį puslapį',
    readmore: 'Daugiau',
    print: 'Spausdinti',
    send: 'Siųsti',
    uploadphoto: 'Įkelti nuotrauką',
    download: 'Parsisiųsti',
    datasheet: 'Duomenų lentelė',
    web: 'internetu',
    copyright: 'SmartVisSolution, visos teisės saugomos, 2021 ©',
    login: 'Prisijungti',
    getstarted: 'Išbandykite',
    testnow: 'Atlikite testą dabar',
    phoneshort: 'tel.',
    email: 'e-paštas',
    name: 'vardas',
    phone: 'telefono nr',
    page404: 'Turinys dar nepatalpintas arba puslapis neegzistuoja.',
    contacts: {
      title: 'Susisiekite su mumis',
      company: 'UAB "Dts Solutions"',
      code: '135885245',
      address1: 'Taikos pr. 88A',
      address2: 'Kaunas, LT-51183',
      country: 'Lietuva',
      phone: '+370 7 266145',
      email: 'info@dts-solutions.lt',
    },
    contactspage: {
      message: 'Mūsų komanda pasiruošusi padėti. Mes laukiame Jūsų žinučių ar vizito. Jei turite klausimų kreipkitės nurodytais kontaktais pateiktais žemiau.',
      questionsMessage: 'Klausimams ar klaidoms',
      questionsSentence: 'Jei turite klausimų ar problemų susijusių su programėle pirma perskaitykite mūsų DUK puslapį',
      faqEmail: 'users@smartvissolution.com',
      faqButton: 'Atidaryti DUK',
      pressEmail: 'linas.eidimtas@dts-solutions.lt',
      pressMessage: 'Spaudai',
      pressKit: 'Failai spaudai',
    }
  }
}

const I18n = createI18n(
  locales,
  translations,
);

export default I18n;
